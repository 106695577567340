import { HiPencil } from "react-icons/hi";
import { useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { FaSearch } from "react-icons/fa";
import Button from './Button';
import Modal from './Modal.tsx';
import AudioButton from './AudioButton';
import Input from './Input.tsx';
import RadioGroupJson from "./RadioGroupJson.tsx";
import { regenerateVoiceClip, getJobStatus } from '../api';
import Caution from "./Caution.tsx";
import loading_animation from "../loading_animation.gif";

export default function Avatar({ imageUrl, character, audioUrl, voiceList, onAcceptVoice }) {
    const { uuid } = useParams();
    const [editing, setEditing] = useState(false);
    const [editedCharacter, setEditedCharacter] = useState({...character});
    const [voiceLoading, setVoiceLoading] = useState(false);
    const [voiceName, setVoiceName] = useState(editedCharacter.name);
    const [selectedVoice, setSelectedVoice] = useState(editedCharacter.voice_token);
    const [userInput, setUserInput] = useState(`Hi, my name is ${character.name}. This is sample audio.`);
    const [audioSample, setAudioSample] = useState(null);
    
    const poll_delay_ms = 6000;
    const max_polls = 20;
    const backoff_factor = 1.2;

    const handleEditClick = () => {
        setEditing(true);
    };


    const filteredResults = useMemo(() => {
        if (!voiceName.trim()) {
            return [];
        }
    
        const lowerCaseInput = voiceName.toLowerCase();
        return voiceList.filter(model =>
          model.title.toLowerCase().includes(lowerCaseInput)
        ).slice(0, 20); // Display only the top 10 matches
    }, [voiceName, voiceList]);

    const handleSampleAudio = async () => {
        console.log(selectedVoice);
        setAudioSample(null);
        if(!selectedVoice) return;
        setVoiceLoading(true);
        const jobId = await regenerateVoiceClip(uuid, userInput, selectedVoice);
        setTimeout(() => pollVoice(jobId), poll_delay_ms);
    }

    useEffect(() => {
        setEditedCharacter(prevCharacter => ({ ...prevCharacter, voice_token: selectedVoice }));
    }, [selectedVoice]);

    const handleAcceptVoice = () => {
        setEditedCharacter(prevCharacter => ({ ...prevCharacter, voice_token: selectedVoice }));
        setEditing(false);
        console.log("Updated voice_token on Avatar:", editedCharacter);
        onAcceptVoice(editedCharacter);
    }
    
    const pollVoice = async (jobId, attempt=1) => {
        console.log('Polling voice job', jobId);
        const res = await getJobStatus(jobId, 'voice');
        if(res.status === 'success'){
          console.log('Voice job complete', res);
          setAudioSample(res.audio_url);
          setVoiceLoading(false);
        }
        else if(res.status === 'failed'){
          console.error('Voice job failed', res);
          setVoiceLoading(false);
          // TODO: handle error
        }
        else{
          if(attempt >= max_polls){
            console.error('Voice job polling limit exceeded');
            setVoiceLoading(false);
          }
          else {
            setTimeout(() => pollVoice(jobId, attempt+1), poll_delay_ms * Math.pow(backoff_factor, attempt));
          }
        }
    };

    return (
        <>
            <HiPencil onClick={handleEditClick} className="absolute cursor-pointer right-0 top-0 w-9 h-9 p-1 bg-orange-200 rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none hover:no-underline hover:text-black"/>
            <div
                style={{
                backgroundImage: `url(${imageUrl})`,
                }}
                className="h-24 w-24 md:h-36 md:w-36 rounded-full border-2 mx-2 border-black bg-cover bg-center shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
            ></div>
            <div className="text-center mt-2">{character.name}</div>

            <Modal 
                active={editing} 
                setActive={() => setEditing(false)} 
                dialogClassName="rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
            >
                <div className="text-xl font-bold mt-4">Edit {editedCharacter.name} Voice</div>
                <div className="max-w-[360px] p-4 w-full">
                    <div className="flex flex-row mx-1">
                        <div className="font-semibold m-1">
                            Hear Voice Sample: 
                        </div>
                        <div className="ml-2">
                            <AudioButton audioSrc={audioUrl} />
                        </div>
                    </div>
                    <div className="flex flex-col mx-1 my-0">
                        <label className="font-semibold m-1 mt-2">Look Up a Voice</label>
                        <div className="relative inline-block text-left">
                            <div className="flex flex-row flex-wrap items-center">
                                <Input value={voiceName} setValue={setVoiceName} />
                            </div>
                        </div>
                    </div>
                    {filteredResults.length > 0 && (
                        <div>
                            <div className="font-semibold m-1 mt-2">
                                Select a Voice Model
                            </div>
                            <div className="truncate max-h-[300px] bg-green-200 max-w-[340px] rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] ">
                                <RadioGroupJson
                                    items={filteredResults} 
                                    value={selectedVoice}
                                    onChange={(value) => {
                                        setSelectedVoice(value)
                                        console.log("Selected Voice:", selectedVoice)}}
                                />
                            </div>
                            
                        </div>
                        
                    )}
                    <div className="mt-2">
                        <div className="font-semibold m-1 mt-2">
                            Choose Sample Audio
                        </div>
                        <textarea
                            type="text"
                            className="min-h-[100px] w-full resize-y required:border-red-500 rounded-md border-2 border-black p-[10px] font-bold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] outline-none transition-all focus:translate-x-[3px] focus:translate-y-[3px] focus:shadow-none"
                            value={userInput}
                            onChange={(e) => {setUserInput(e.target.value)}}
                            placeholder={`Hi, my name is ${character.name}. This is sample audio.`}
                        ></textarea>
                    </div>
                    <Button text="Generate Sample" width="w-100" disabled={voiceLoading} onClick={handleSampleAudio} color={`bg-green-400`} variant="regen" margin="my-2" />
                    {voiceLoading && (
                        <img src={loading_animation} alt="Loading animation" className="w-[100px] mx-auto mt-1" />
                    )}
                    {audioSample && (
                        <div className="flex flex-row mx-1 mt-3">
                            <div className="font-semibold m-1">
                                Play New Voice Sample: 
                            </div>
                            <div className="ml-2">
                                <AudioButton audioSrc={audioSample} />
                            </div>
                        </div>
                    )}
                    {/* Horizontal Line */}
                    <div className="flex-grow px-3 mt-3 mb-2 items-center">
                        <hr className="border-t border-[rgba(0,0,0,0.5)]" />
                    </div>
                    <Caution message="Caution!" />
                    <div className="p-2">
                        Clicking "Accept" will regenerate all audio in your script for this character. 
                        This process may take several minutes, depending on how many lines this character has. 
                    </div>
                </div>
                <div className="w-100 flex flex-row justify-between gap-2 p-2">
                    <Button text="Cancel" onClick={() => setEditing(false)} color={`bg-stone-300`} />
                    <Button text="Accept Voice" onClick={handleAcceptVoice} color={`bg-cyan-300`} disabled={voiceLoading}/>
                </div>
            </Modal>
        </>
    )
  }