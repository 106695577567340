import React, { useState, useEffect } from 'react';
import Avatar from './Components/Avatar';
import { getVoiceList } from './api';
import AddCharacter from './AddCharacter';

const Characters = ({ script, onAcceptVoice, onAddCharacter }) => {
    const characters = script.characters;
    const clips = script.clips.length > 0 ? script.clips : [{speaker: "No clips", speech: "No speech", image_url: null, audio_url: null}];
    const [voiceList, setVoiceList] = useState([]);

    useEffect(() => {
        async function loadVoices(){
          const res = await getVoiceList();
          const voices = res?.models || [];
          setVoiceList(voices);
        }
        
        loadVoices();
      }, []);

    const getFirstClipIndex = (characterName) => {
        const firstClip = clips.find((clip) => clip.speaker === characterName);
        return firstClip ? clips.indexOf(firstClip) : 0;
    };

    return (
        <>
            <div className="bg-cyan-100 font-bold rounded-md shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] border-2 border-black px-4 py-2">
                <div className="text-2xl mb-3 p-2 font-bold">
                    <div>Characters</div>
                </div>
                <div className="flex justify-around items-end flex-wrap">
                    {characters.map((character, index) => (
                        <div key={index} className="flex flex-col items-center relative">
                            <Avatar 
                                imageUrl={clips[getFirstClipIndex(character.name)].image_url} 
                                character={character} 
                                audioUrl={clips[getFirstClipIndex(character.name)].audio_url}
                                voiceList={voiceList}
                                onAcceptVoice={onAcceptVoice}
                            />
                        </div>
                    ))}
                </div>
                <AddCharacter voiceList={voiceList} onAddCharacter={onAddCharacter}/>
            </div>
        </>
    );
};

export default Characters;