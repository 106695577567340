import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.jsx";
import NavBar from "./Navbar.jsx";
import logoText from "./LogoText.png";
import { useNavigate } from "react-router-dom";
import { generateVideo, getGenres } from "./api";
import Button from "./Components/Button";
import Select from "./Components/Select.tsx";
import { FiPlus } from "react-icons/fi";
import RadioGroup from "./Components/RadioGroup.tsx";
import RecentVideos from "./RecentVideos.jsx";
import { v4 as uuidv4 } from "uuid";

export default function Homepage() {
  const [userInput, setUserInput] = useState("");
  const navigate = useNavigate();
  const [genres, setGenres] = useState([]);
  const [scriptGenre, setScriptGenre] = useState("standard");
  const [showOptions, setShowOptions] = useState(false);
  const [contentHeight, setContentHeight] = useState("0px");
  const contentRef = useRef(null);
  const orientations = ["portrait", "landscape", "square"];
  const [orientation, setOrientation] = useState("portrait");
  const [requirePrompt, setRequirePrompt] = useState(false);

  useEffect(() => {
    async function loadGenres() {
      const res = await getGenres();
      const genres = res.genres;
      setGenres(genres);
    }

    loadGenres();
  }, []);

  const isMobileView = () => {
    return window.innerWidth < 768;
  };

  const submitText = async () => {
    if (!userInput.trim()) {
      // Prompt is empty, display an error message or prevent submission
      setRequirePrompt(true);
      return;
    }
    try {
      const uuid = await generateVideo(userInput, scriptGenre, orientation);
      if (uuid) {
        console.log("Video generation request succeeded.");
        navigate(`/video/${uuid}`);
      } else {
        console.error("Video generation request failed");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleCreateClick = () => {
    const uuid = uuidv4();
    navigate(`/create/${uuid}`);
  };

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(`${contentRef.current.scrollHeight}px`);
    }
  }, [showOptions]);

  // "Standard" should always come first, alphabetically after that
  const sortedGenres = genres.sort((a, b) => (a > b ? 1 : -1));
  const sortedGenresStandardFirst = [
    "standard",
    ...sortedGenres.filter((g) => g !== "standard"),
  ];

  return (
    <>
      <NavBar>
        <div>
          <div className="font-semibold mt-2">Writing a Prompt</div>
          <div className="text-sm space-y-2">
            <p>
              Feel free to try to include characters from your favorite games,
              shows, or movies, or try writing a more open-ended prompt.
            </p>
            <p>
              Sitcom Simulator will not generate lewd, violent, or pornographic
              videos. Trying to generate a video like that will result in an
              error.
            </p>
          </div>
          <div className="font-semibold mt-2">Genres</div>
          <div className="text-sm space-y-2">
            <p>
              Choosing a genre will provide different instructions to the AI
              creating your script, allowing it to create a different style of
              video.
            </p>
            <p>
              Sitcom Simulator currently supports several different genres, and
              we'll be adding more as time goes on.
            </p>
          </div>
          <div className="font-semibold mt-2">Advanced Options</div>
          <div className="text-sm space-y-2">
            <p>
              Here you may choose the orientation of a video. You can also
              create a video from scratch, giving you a blank slate to start
              from. All the scriptwriting will be done by you, not the AI.
            </p>
            <p>More options will be added as time goes on.</p>
          </div>
          <div className="font-semibold mt-2">Generate</div>
          <div className="text-sm space-y-2">
            <p>
              Once you've entered your prompt and chosen a genre, click the
              Generate button to generate a video. Video generation will take
              about 5 minutes.
            </p>
          </div>
          <div className="font-semibold mt-2">Recently Viewed Videos</div>
          <div className="text-sm space-y-2">
            <p>
              Here you will find videos that you have recently viewed. To delete
              them from your history, simply click the red "X".
            </p>
          </div>
          <div className="font-semibold mt-2">About</div>
          <div className="text-sm space-y-2">
            <p>
              Sitcom Simulator is a project by Josh Moody, Cole Smith, and
              Michael Davis.
            </p>
          </div>
        </div>
      </NavBar>
      <img
        src={logoText}
        className="mx-auto mt-2 h-auto w-10/12 md:w-auto max-w-[300px]"
        alt="Logo"
      />
      <Header
        title="Type. Click. Watch."
        subtitle="Turn your weirdest meme idea into reality with just one click. Our AI meme generator can handle anything you throw at it."
      />
      <div className="w-full mx-2 px-2">
        <div className="w-full md:w-[400px] mx-auto">
          <div className="flex flex-col mx-1 my-0">
            <div className="font-semibold mx-1 mt-2 text-left">
              1. Write a Prompt
            </div>
            <textarea
              type="text"
              className="min-h-[150px] resize-y required:border-red-500 rounded-md border-2 border-black p-[10px] font-bold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] outline-none transition-all focus:translate-x-[3px] focus:translate-y-[3px] focus:shadow-none"
              value={userInput}
              onChange={(e) => {
                setUserInput(e.target.value);
                setRequirePrompt(false);
              }}
              placeholder="A sitcom in which Luigi eats a skateboard whole. Mario is shocked and horrified."
              required={requirePrompt}
            ></textarea>
          </div>
          <div
            className={`flex ${
              isMobileView() ? "flex-col" : "flex-row"
            } mx-1 my-0 justify-between`}
          >
            <div className="flex flex-col mx-1 my-0">
              <div className="font-semibold mx-1 mt-2 text-left">
                2. Choose a Genre
              </div>
              <Select
                items={sortedGenresStandardFirst}
                value={scriptGenre}
                onChange={(value) => setScriptGenre(value)}
              />
            </div>
            <div className="flex flex-col mx-1 my-0">
              <div className="font-semibold mx-1 mt-2 text-left">
                3. Click and Wait
              </div>
              <div className="items-left">
                <Button
                  text="Generate"
                  onClick={submitText}
                  style={`bg-cyan-300`}
                  margin={""}
                />
              </div>
            </div>
          </div>
          {requirePrompt && (
            <p className="text-red-500 font-bold">
              Write a prompt to generate a video.
            </p>
          )}
          {/* Horizontal Line */}
          <div className="flex-grow px-3 my-2 items-center">
            <hr className="border-t border-[rgba(0,0,0,0.5)]" />
          </div>
          {/* Show Advanced Options Button */}
          <div className="flex flex-col mx-1 my-0">
            <div className="w-full max-w-[400px] mx-auto my-3 rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
              <button
                aria-expanded={showOptions}
                style={{ borderBottom: showOptions ? "solid 2px" : "0px" }}
                className="flex w-full items-center justify-between rounded-[5px] border-black bg-blue-200 px-5 py-4 font-bold"
                onClick={() => {
                  setShowOptions(!showOptions);
                }}
              >
                {showOptions
                  ? "Hide Advanced Options"
                  : "Show Advanced Options"}
                <FiPlus
                  style={{
                    transform: `rotate(${showOptions ? "45deg" : "0"})`,
                  }}
                  className="ml-4 min-h-[24px] min-w-[24px] transition-transform ease-in-out"
                />
              </button>
              <div
                ref={contentRef}
                style={{ height: showOptions ? `${contentHeight}` : "0" }}
                className="overflow-hidden rounded-[5px] bg-white font-bold transition-[height] ease-in-out"
              >
                <div className="p-2">
                  {/* Advanced Options */}
                  <div className="bg-green-200 border-2 p-2 border-black rounded-md shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
                    <div className="font-semibold text-left">
                      Video Orientation
                    </div>
                    <RadioGroup
                      items={orientations}
                      value={orientation}
                      onChange={(value) => setOrientation(value)}
                    />
                  </div>
                  <div className="pt-3">
                    <Button
                      text="Create A Video From Scratch"
                      onClick={handleCreateClick}
                      color={`bg-orange-200`}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* LocalStorage Videos */}
            <RecentVideos />
          </div>
        </div>
      </div>
    </>
  );
}
