import React from 'react';
import { RiAlertFill } from "react-icons/ri";

export default function Caution({ message }: { message: string }) {
  return (
    <div
      role="caution"
      className="flex items-center justify-center rounded-md border-2 border-black bg-amber-300 p-5 px-8 font-bold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
    >
      <RiAlertFill className="mr-3 h-6 min-h-[24px] w-6 min-w-[24px]" />
      {message}
    </div>
  )
}