import React, { useState } from 'react';
import Modal from './Components/Modal.tsx';
import Select from './Components/Select.tsx';
import Button from './Components/Button';
import Tabs from './Components/Tabs.tsx';
import Tab from './Components/Tab.tsx';
import loading_animation from "./loading_animation.gif";
import { regenerateImage, regenerateVoiceClip, getJobStatus } from './api';
import { IoIosAdd } from "react-icons/io";

const AddClip = ({ script, onAddClip, clipIndex, creatingFromScratch = false }) => {
  const [showModal, setShowModal] = useState(false);
  const [newClip, setNewClip] = useState({
    speaker: '',
    speech: '',
    image_prompt: '',
    image_url: '',
    audio_url: '',
  });
  const [imageLoading, setImageLoading] = useState(false);
  const [voiceLoading, setVoiceLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('audio');

  const poll_delay_ms = 6000;
  const max_polls = 20;
  const backoff_factor = 1.2;

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    // Reset newClip state when the modal is closed
    setNewClip({
      speaker: '',
      speech: '',
      image_prompt: '',
      image_url: '',
      audio_url: '',
    });
  };

  const handleRegenerateImage = async () => {
    setImageLoading(true);
    const jobId = regenerateImage(
      script.metadata.root_script_id || script.id,
      newClip.image_prompt,
      creatingFromScratch,
      creatingFromScratch ? script.metadata.orientation : undefined,
      )
      .then((jobId) => {
        setTimeout(() => pollImage(jobId), poll_delay_ms);
      });
  };

  const pollImage = async (jobId, attempt=1) => {
    console.log('Polling image job', jobId);
    const res = await getJobStatus(jobId, 'image');
    if(!res?.status){
      console.error('Image job status not found', res);
      setImageLoading(false);
      // TODO: handle error in UI
    }
    if(res.status === 'success'){
      console.log('Image job complete', res);
      setNewClip({ ...newClip, image_url: res.image_url });
      setImageLoading(false);
    }
    else if(res.status === 'failed'){
      console.error('Image job failed', res);
      setImageLoading(false);
      // TODO: handle error
    }
    else{
      if(attempt >= max_polls){
        console.error('Image job polling limit exceeded');
        setImageLoading(false);
      }
      else {
        setTimeout(() => pollImage(jobId, attempt+1), poll_delay_ms * Math.pow(backoff_factor, attempt));
      }
    }
  }

  const handleRegenerateAudio = async () => {
    const speaker = script.characters.find(c => c.name === newClip.speaker);
    console.log(speaker, newClip, script.characters);
    if(!speaker) return;
    setVoiceLoading(true);
    const jobId = await regenerateVoiceClip(script.metadata.root_script_id || script.id, newClip.speech, speaker.voice_token);
    setTimeout(() => pollVoice(jobId), poll_delay_ms);
  };

  const pollVoice = async (jobId, attempt=1) => {
    console.log('Polling voice job', jobId);
    const res = await getJobStatus(jobId, 'voice');
    if(res.status === 'success'){
      console.log('Voice job complete', res);
      setNewClip({ ...newClip, audio_url: res.audio_url });
      setVoiceLoading(false);
    }
    else if(res.status === 'failed'){
      console.error('Voice job failed', res);
      setVoiceLoading(false);
      // TODO: handle error
    }
    else{
      if(attempt >= max_polls){
        console.error('Voice job polling limit exceeded');
        setVoiceLoading(false);
      }
      else {
        setTimeout(() => pollVoice(jobId, attempt+1), poll_delay_ms * Math.pow(backoff_factor, attempt));
      }
    }
  };

  const handleAddClip = () => {
    // Add the newClip to the script's clips array
    onAddClip(newClip, clipIndex);
    // Close the modal
    handleCloseModal();
  };

  return (
    <>
      <div className="w-full md:w-[360px] relative m-auto">
        {/* Your Add Clip UI/Button */}
        <div className="flex text-center mt-2 items-center">
            <div className="flex-grow px-3">
                <hr className="border-t border-black" />
            </div>
            <span className="">
                <IoIosAdd onClick={handleOpenModal} className="cursor-pointer mx-auto bg-blue-300 w-9 h-9 p-1 rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none hover:no-underline hover:text-black"/>
            </span>
            <div className="flex-grow px-3">
                <hr className="border-t border-black" />
            </div>
        </div>

        <Modal 
            active={showModal} 
            setActive={handleCloseModal} 
        >
            <Tabs
            tabsArray={["image", "audio"]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            >
            <Tab tabName="image" activeTab={activeTab}>
                <h4 className="font-black text-2xl mt-2 text-center">Add Clip Image</h4>
                <div className="flex flex-col mx-1 my-2">
                    {newClip.image_url !== '' && (
                        <img src={newClip.image_url} alt={newClip.image_prompt} className="w-100 mx-auto rounded-md border-2 border-black border-solid shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]" style={{maxWidth: '16rem'}}/>
                    )}      
                    {imageLoading && (
                        <img src={loading_animation} alt="Loading animation" className="w-[100px] mx-auto mt-1" />
                    )}
                    <label className="font-semibold m-1 mt-2">Image Prompt</label>
                    <textarea
                    value={newClip.image_prompt}
                    placeholder="Write an image prompt to generate an image."
                    onChange={(e) => setNewClip({ ...newClip, image_prompt: e.target.value })}
                    className="h-[150px] w-full md:w-[265px] resize-vertical rounded-md border-2 border-black p-[10px] shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] outline-none transition-all focus:translate-x-[3px] focus:translate-y-[3px] focus:shadow-none"
                    rows="5"
                    disabled={imageLoading}
                    />
                    {newClip.image_prompt !== '' && (
                        <Button text="Generate Image" disabled={imageLoading} onClick={handleRegenerateImage} width="w-100" color={`bg-green-400`} variant="regen" margin="mx-auto my-2" />
                    )}
                </div>
            </Tab>
            <Tab tabName="audio" activeTab={activeTab}>
                <h4 className="font-black text-2xl mt-2 text-center">Add Clip Audio</h4>
                <div className="max-w-[265px]">
                    <div className="flex flex-col mx-1 my-2">
                        {newClip.audio_url !== '' && (
                            <>
                                <label className="font-semibold m-1 mt-2">Audio</label>
                                <audio controls key={newClip.audio_url} className="rounded-md w-full border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
                                <source src={newClip.audio_url} type="audio/wav"/>
                                Your browser does not support the audio tag.
                                </audio>
                            </>
                        )}
                        
                        {voiceLoading && (
                            <img src={loading_animation} alt="Loading animation" className="w-[100px] mx-auto mt-1" />
                        )}
                    </div>
                    <div className="flex flex-col mx-1 my-0">
                        <label className="font-semibold m-1 mt-2">Speaker</label>
                        <div className="relative inline-block text-left">
                            <Select items={script.characters} onChange={(value) => setNewClip({ ...newClip, speaker: value })} value={newClip.speaker} />
                        </div>
                    </div>
                    <div className="flex flex-col mx-1 my-0">
                        <label className="font-semibold m-1 mt-2">Speech</label>
                        <textarea
                            type="text"
                            value={newClip.speech}
                            placeholder="Select a speaker and write their line to generate audio."
                            onChange={(e) => setNewClip({ ...newClip, speech: e.target.value })}
                            className="h-[150px] w-full md:w-full mb-2 resize-vertical rounded-md border-2 border-black p-[10px] shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] outline-none transition-all focus:translate-x-[3px] focus:translate-y-[3px] focus:shadow-none"
                            rows="5"
                            disabled={voiceLoading}
                        />
                    </div>
                    {newClip.speech !== '' && newClip.speaker !== '' && (
                        <Button text="Generate Audio" width="w-100" disabled={voiceLoading} onClick={handleRegenerateAudio} color={`bg-green-400`} variant="regen" margin="mx-auto my-2" />
                    )}                    
                </div>
            </Tab>
            </Tabs>
            <div className="flex flex-row md:flex-row items-center py-3 mt-1 bg-gray-100 w-full">
                <Button text="Cancel" onClick={handleCloseModal} color={`bg-stone-300`}/>
                <Button text="Insert Clip" onClick={handleAddClip} disabled={imageLoading || voiceLoading }/>
            </div>
        </Modal>
      </div>
    </>
  );
};

export default AddClip;