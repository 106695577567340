import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { MdClose } from 'react-icons/md';

type Props = {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  closeable?: boolean;
};

export default function Modal({ active, setActive, children, closeable=true }: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const modalContentRef = useRef(null); // Using useRef to access the modal content
  const [fading, setFading] = useState(false);

  useEffect(() => {
    let timeoutId;
    setFading(true);
    setTimeout(() => {
      setFading(false);
    }, 200);
    if (active) {
      setIsVisible(true);
      // Ensure modal content is initially invisible for fade-in effect
      if (modalContentRef.current) {
        modalContentRef.current.style.opacity = '0';
      }
      // Wait for the next frame to ensure the element is visible before starting the fade-in
      timeoutId = setTimeout(() => {
        if (modalContentRef.current) {
          modalContentRef.current.style.opacity = '1';
        }
      }, 10); // A small delay to ensure the transition happens
    } else {
      setIsVisible(false);
      // Start fade-out immediately
      if (modalContentRef.current) {
        modalContentRef.current.style.opacity = '0';
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [active, isVisible]);

  if (!active && !isVisible && !fading) return null;

  return ReactDOM.createPortal(
    <div
      role="dialog"
      aria-modal="true"
      className={`fixed left-0 top-0 flex h-screen w-screen items-start justify-center z-40 bg-black/50 overflow-y-auto transition-opacity duration-00 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'}`}
    >
      <div
        ref={modalContentRef}
        className="relative mx-auto my-6 w-11/12 flex md:max-w-[400px] flex-col items-center justify-center rounded-md border-2 border-black bg-white shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
        style={{ transition: 'opacity 200ms ease-in-out' }}
      >
        {closeable && (
          <button onClick={() => setActive(false)}>
            <MdClose className="absolute right-3 top-3 h-6 w-6" />
          </button>
        )}
        {children}
      </div>
    </div>,
    document.getElementById('modal') as HTMLElement,
  );
}
