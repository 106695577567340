import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import { IoChevronBackOutline } from "react-icons/io5";

type Props = {
  active: boolean
  setActive: React.Dispatch<React.SetStateAction<boolean>>
  children: string | JSX.Element | JSX.Element[]
  title: string 
}

export default function Drawer({ active, setActive, children, title="How To Use" }: Props) {
  const [isVisible, setIsVisible] = useState(false)

  const closeDrawer = () => {
    setIsVisible(false)
    setTimeout(() => {
      setActive(false)
    }, 300)
  }

  useEffect(() => {
    if (active) {
      setIsVisible(true)
    }
  }, [active])

  if (!active) return null

  return ReactDom.createPortal(
    <div
      role="dialog"
      aria-modal="true"
      style={{
        opacity: isVisible ? '1' : '0',
        visibility: isVisible ? 'visible' : 'hidden',
      }}
      onClick={closeDrawer}
      className="fixed left-0 top-0 z-50 flex h-[100dvh] w-screen items-start justify-start bg-gray-500/50 transition-all duration-300"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          transform: `translateX(${isVisible ? '0' : '-300px'})`,
        }}
        className="z-10 h-full overflow-auto w-[300px] border-r-2 border-black bg-[#C4A1FF] font-semibold transition-transform duration-300"
      >
        <div className="flex flex-row md:text-lg text-center items-center my-2 justify-between border-b-2 border-black">
          <div className="flex flex-row items-center justify-center mx-auto">
            <h1 className="mx-auto">{title}</h1>
          </div>
          <button
            onClick={closeDrawer}
            className="flex items-center mr-2 mb-2 bg-white justify-center rounded-md border-2 border-black p-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none"
          >
            <IoChevronBackOutline className='h-4 w-4' />
          </button>
        </div>
        <div className="mx-2">
          {children}
        </div>
        
      </div>
    </div>,
    document.getElementById('drawer') as HTMLElement,

    // don't forget to add
    // <div id="drawer"></div>
    // to index.html
  )
}