import React from 'react';
import {
  BrowserRouter as Router, Route, Routes
} from "react-router-dom";
import Homepage from './Homepage';
import Video from './Video';
import ErrorPage from './error-page';
import Edit from './Edit';
import Create from './Create';

const App = () => (
  <div className="flex flex-col justify-center text-black box-border text-center items-center ">
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} errorElement= {<ErrorPage />} />
        <Route path="/video/:uuid" element={<Video />} errorElement= {<ErrorPage />} />
        <Route path="/video/edit/:uuid" element={<Edit />} errorElement= {<ErrorPage />} />
        <Route path="/create/:uuid" element={<Create />} errorElement= {<ErrorPage />} />
      </Routes>
    </Router>
  </div>
);

export default App;