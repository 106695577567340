import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import "./App.css";
import { getScript } from "./api";
import CopyUrlButton from "./Components/CopyUrlButton";
import Header from "./Header";
import Button from "./Components/Button";
import Accordion from "./Components/Accordion.tsx";
import Alert from "./Components/Alert.tsx";
import Navbar from "./Navbar";
import loading_animation from "./loading_animation.gif";
import ProgressBar from "./Components/ProgressBar";
import ShareButton from "./Components/ShareButton";
import YouTubeShorts from "./YouTubeShorts";

export default function Video() {
  const { uuid } = useParams();

  // State to store the script
  const [data, setData] = useState(null);
  const status = data?.status ?? "loading";
  const script = data?.script;

  const intervalIdRef = useRef(null);

  const pollingFrequency = 5 * 1000; // milliseconds

  const complete = status.toLowerCase().includes("complete");
  const failed =
    script?.status?.toLowerCase().includes("failed") ||
    status.toLowerCase().includes("failed");

  const addToLocalStorage = (uuid, title, imageUrl, createdAt) => {
    const localStorageKey = "scripts";
    const scripts = JSON.parse(localStorage.getItem(localStorageKey)) || [];

    // Check if the entry already exists based on UUID
    const existingEntry = scripts.find((entry) => entry.uuid === uuid);

    if (!existingEntry) {
      // If the entry doesn't exist, add a new one
      const newEntry = {
        title,
        uuid,
        imageUrl,
        createdAt,
      };

      const updatedScripts = [newEntry, ...scripts];

      // Update the localStorage
      localStorage.setItem(localStorageKey, JSON.stringify(updatedScripts));
    }
  };

  // Poll script status at fixed intervals
  useEffect(() => {
    if (!uuid || complete) return;

    async function loadScript() {
      const data = await getScript(uuid);
      setData(data);
    }

    loadScript();
    const newIntervalId = setInterval(loadScript, pollingFrequency);
    intervalIdRef.current = newIntervalId;

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalIdRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, complete, pollingFrequency]);

  useEffect(() => {
    if (!complete) return;
    console.log("Video complete, cancelling polling.");
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = null;

    if (script) {
      addToLocalStorage(
        uuid,
        script.metadata.title,
        script.clips[0].image_url,
        script.metadata.date_created
      );
    }
  }, [complete, script, uuid]);

  useEffect(() => {
    if (!failed) return;
    console.log("Video failed, cancelling polling.");
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = null;
  }, [failed]);

  // extremely temporary proof-of-concept (DEPRECATED)
  // for long videos, can easily exceed the lambda concurrency limit, since they all come in simulataneously
  // requiring a page refresh
  // useEffect(() => {
  //   async function temp(){
  //     await addAudioToClip(uuid, null, null);
  //   }
  //   if(status?.toLowerCase()?.includes('generating audio') || status?.toLowerCase()?.includes('rendering final')){ // the rendering final is extremely temporary
  //     console.log("Adding audio to clips debug")
  //     temp()
  //     return;
  //     if(!script?.clips) return;
  //     const clipsThatNeedAudio = script.clips.filter(clip => !clip.audio_url);
  //     if(clipsThatNeedAudio.length > 0){
  //       console.log("Clips that need audio:", clipsThatNeedAudio);
  //       // with a delay between each clip, send a post request to the backend with a placeholder url for each audio clip
  //       // for now, just use a placeholder url
  //       const placeholderAudio = "https://storage.googleapis.com/vocodes-public/media/d/n/s/5/d/dns5d94b0rcmkmyybvt54xrnsj48bz94/fakeyou_dns5d94b0rcmkmyybvt54xrnsj48bz94.wav";
  //       clipsThatNeedAudio.forEach(async clip => {
  //         await addAudioToClip(uuid, script.clips.indexOf(clip), placeholderAudio);
  //       });
  //     }
  //   }
  // }, [status])

  return (
    <>
      <Navbar title={complete ? "Your Video" : "Video Loading"}>
        <div>
          <div className="font-semibold mt-2">Waiting for your video</div>
          <div className="text-sm space-y-2">
            <p>
              While your video is loading, feel free to close this tab and come
              back to it later. Just make sure to copy the URL before you go.
            </p>
            <p>
              You can also browse some videos that others have created, featured
              on our Youtube Channel.
            </p>
          </div>
          <div className="font-semibold mt-2">Watch</div>
          <div className="text-sm space-y-2">
            <p>
              Once your video has loaded, feel free to share this link so others
              can watch, or download your video. To share the video, simply
              click the icon on the top right of the video.
            </p>
          </div>
          <div className="font-semibold mt-2">Edit</div>
          <div className="text-sm space-y-2">
            <p>
              If you'd like to make modifications or additions, click the Edit
              Video button.
            </p>
            <p>
              If you'd like to start over, or see the genius that inspired this
              masterpiece, you can view your prompt by clicking the "Video
              Prompt" area.
            </p>
          </div>
          <div className="font-semibold mt-2">Donate</div>
          <div className="text-sm space-y-2">
            <p>
              If you enjoyed using Sitcom Simulator, feel free to support us by
              clicking the venmo link labeled "Buy us a 44oz Utah Mom Dirty Diet
              Coke".
            </p>
            <p>
              All proceeds will go towards the development of Sitcom Simulator
              including, but not limited to, some soda to keep us motivated.
            </p>
          </div>
        </div>
      </Navbar>
      <Header
        title={
          complete
            ? script?.metadata?.title || "Unknown Video"
            : failed
            ? "Video Failed"
            : "Your Video is Being Made!"
        }
      />
      <main>
        <div className="container">
          <div>
            {data ? (
              <div>
                {!complete && !failed && (
                  <>
                    <div className="">
                      <ProgressBar status={status} />
                    </div>
                    <div className="w-full md:w-80 mx-auto bg-white overflow-hidden p-4 my-2 rounded-md border-black border-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
                      <p className="uppercase md:mt-4">
                        <b>
                          {status}
                          {complete ? "!" : <>&hellip;</>}
                        </b>
                      </p>
                      <img
                        src={loading_animation}
                        alt="Loading..."
                        className="mx-auto"
                      />
                      <p className="max-w-[300px] text-sm md:text-base  text-left mx-auto p-3">
                        You can close this page and return anytime to check on
                        the status of your video. The page will update to
                        display the completed video when it's done rendering.
                        This process will likely take 5 minutes to complete.
                      </p>
                      <CopyUrlButton />
                    </div>
                    {script?.metadata?.prompt && (
                      <div className="max-w-[300px] mx-auto">
                        <Accordion
                          question="Video Prompt"
                          answer={script.metadata.prompt}
                        />
                      </div>
                    )}
                  </>
                )}

                {failed && (
                  <div className="w-full max-w-[360px] mx-auto">
                    <Alert message="Uh Oh! Your video failed to generate." />
                    <div className="bg-white overflow-hidden p-4 my-2 rounded-md border-black border-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
                      <p className="max-w-[580px] font-semibold">
                        This could be due to a couple of factors:
                      </p>
                      <ul className="list-disc text-left p-3">
                        <li>
                          An error on our end. Our bad! Please try again later.
                        </li>
                        <li>
                          Your prompt may have been flagged as inappropriate.
                          Try editing your prompt and regenerating your video.
                        </li>
                      </ul>
                      <Link
                        to={`/`}
                        className="hover:no-underline hover:text-black ml-auto mr-0"
                      >
                        <Button text="Return Home" />
                      </Link>
                      <Accordion
                        question="Video Prompt"
                        answer={data.metadata.prompt}
                      />
                    </div>
                  </div>
                )}

                {complete && (
                  <>
                    <div className="max-w-sm mx-auto relative">
                      <video
                        playsInline
                        controls
                        className="rounded-md cursor-pointer border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
                      >
                        <source src={script.video_url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <div className="z-40 absolute -top-2 -right-2">
                        <ShareButton
                          url={window.location.href}
                          title={
                            script?.metadata?.title ||
                            "My Sitcom Simulator Video"
                          }
                        />
                      </div>
                    </div>
                    <br />
                    {/* Download link */}
                    {/* custom name doesn't work because download is from different origin */}
                    <div className="flex flex-wrap items-stretch gap-4 mb-3">
                      <a
                        href={script.video_url}
                        download={`${script?.metadata?.title || uuid}.mp4`}
                        className="flex cursor-pointer items-center rounded-md border-2 border-black py-3 font-bold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none hover:no-underline mb-2 bg-cyan-300 px-4 ml-0 hover:text-black"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Download Video
                      </a>
                      <Link
                        to={`/video/edit/${uuid}`}
                        className="hover:no-underline ml-auto mr-0 hover:text-black"
                      >
                        <Button
                          text="Edit Video"
                          style={`px-4 bg-green-400`}
                          color={`bg-green-400`}
                          margin={`mr-0`}
                        />
                      </Link>
                    </div>
                    <Accordion
                      question="Video Prompt"
                      answer={script.metadata.prompt}
                    />
                    <div className="bg-white mb-3 mx-auto p-3 rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] max-w-[360px]">
                      <p>Want to support this project?</p>
                      <a
                        className="text-blue-500 font-semibold"
                        href="https://account.venmo.com/u/joshmoody24"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Buy us a 44oz Utah Mom Dirty Diet Coke
                      </a>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div>
                {/* Display loading indicator */}
                <p>Loading...</p>
              </div>
            )}
          </div>
          <div className="mx-auto max-w-[360px]">
            <YouTubeShorts />
          </div>
        </div>
      </main>
    </>
  );
}
