export default function Header({title, subtitle}){

    return (
    <header>
        <div className="container">
            <h1 className="text-2xl md:text-4xl lg:text-5xl text-center mt-2 md:mt-6 mb-3">{title}</h1>
            {subtitle && (
                <>
                    <h2 className="text-gray-950 mx-auto text-md md:text-lg text-center mt-3 mb-1 md:mb-6 max-w-lg">{subtitle}</h2>
                </>
            )}
        </div>
  </header>
  );
}