import { useState } from 'react';
import Button from './Button';

const CopyUrlButton = () => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Copy the URL to the clipboard
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
        // Set copied to true if successful
        setCopied(true);
      })
      .catch((error) => {
        console.error('Failed to copy to clipboard', error);
      });
  };

  return (
    <Button text={copied ? 'Copied!' : 'Copy URL'} onClick={copyToClipboard} color={`bg-cyan-300`} margin={`mx-auto mt-2`}/>
  );
};

export default CopyUrlButton;