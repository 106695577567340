import React, { useEffect, useState } from 'react';
import { HiPencil } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import { regenerateImage, regenerateVoiceClip, getJobStatus } from './api';
import Button from './Components/Button';
import Select from './Components/Select.tsx';
import Modal from './Components/Modal.tsx';
import Tabs from './Components/Tabs.tsx';
import Tab from './Components/Tab.tsx';
import loading_animation from "./loading_animation.gif";

const ClipCard = ({ script, index, clip, onEdit, onDelete, creatingFromScratch = false }) => {
  const [editing, setEditing] = useState(false);
  const [editedClip, setEditedClip] = useState({...clip});
  const [imageLoading, setImageLoading] = useState(false);
  const [voiceLoading, setVoiceLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('image'); // ['image', 'audio']
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const poll_delay_ms = 6000;
  const max_polls = 20;
  const backoff_factor = 1.2;

  useEffect(() => {
    setEditedClip({ ...clip });
  }, [clip]);

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    // Trigger the onDelete callback with the index of the current clip
    onDelete(index);
    // Close the confirmation modal
    setShowDeleteModal(false);
  };

  const handleSaveClick = () => {
    // Update the original data with edited values
    onEdit(editedClip, index);
    setEditing(false);
  };

  const handleCancelClick = () => {
    // Reset edited values and exit edit mode
    setEditedClip({ ...clip });
    setEditing(false);
  };

  const handleRegenImage = () => {
    setImageLoading(true);
    const jobId = regenerateImage(
      script.metadata.root_script_id || script.id,
      editedClip.image_prompt,
      creatingFromScratch,
      creatingFromScratch ? script.metadata.orientation : undefined,
    )
      .then((jobId) => {
        setTimeout(() => pollImage(jobId), poll_delay_ms);
      });
  };

  const pollImage = async (jobId, attempt=1) => {
    console.log('Polling image job', jobId);
    const res = await getJobStatus(jobId, 'image');
    if(!res?.status){
      console.error('Image job status not found', res);
      setImageLoading(false);
      // TODO: handle error in UI
    }
    if(res.status === 'success'){
      console.log('Image job complete', res);
      setEditedClip({ ...editedClip, image_url: res.image_url });
      setImageLoading(false);
    }
    else if(res.status === 'failed'){
      console.error('Image job failed', res);
      setImageLoading(false);
      // TODO: handle error
    }
    else{
      if(attempt >= max_polls){
        console.error('Image job polling limit exceeded');
        setImageLoading(false);
      }
      else {
        setTimeout(() => pollImage(jobId, attempt+1), poll_delay_ms * Math.pow(backoff_factor, attempt));
      }
    }
  }

  const handleRegenAudio = async () => {
    const speaker = script.characters.find(c => c.name === editedClip.speaker);
    console.log(speaker, clip, script.characters);
    if(!speaker) return;
    setVoiceLoading(true);
    const jobId = await regenerateVoiceClip(script.metadata.root_script_id || script.id, editedClip.speech, speaker.voice_token);
    setTimeout(() => pollVoice(jobId), poll_delay_ms);
  };

  const pollVoice = async (jobId, attempt=1) => {
    console.log('Polling voice job', jobId);
    const res = await getJobStatus(jobId, 'voice');
    if(res.status === 'success'){
      console.log('Voice job complete', res);
      setEditedClip({ ...editedClip, audio_url: res.audio_url });
      setVoiceLoading(false);
    }
    else if(res.status === 'failed'){
      console.error('Voice job failed', res);
      setVoiceLoading(false);
      // TODO: handle error
    }
    else{
      if(attempt >= max_polls){
        console.error('Voice job polling limit exceeded');
        setVoiceLoading(false);
      }
      else {
        // exponential backoff with jitter
        setTimeout(() => pollVoice(jobId, attempt+1), poll_delay_ms * Math.pow(backoff_factor, attempt) + (Math.random() * 1000));
      }
    }
  };

  const handleSelectChange = (value, property) => {
    // Update the corresponding property in the edited clip
    setEditedClip({ ...editedClip, [property]: value });
  };

  const handleInputChange = (event, property) => {
    // Update the corresponding property in the edited clip
    setEditedClip({ ...editedClip, [property]: event.target.value });
  };

  return (
    <>
      <div>
        <div className="w-full max-w-[360px] my-3 mx-auto bg-indigo-100 rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
          <div className="w-full max-h-[360px] overflow-hidden">
            <img src={clip.image_url} alt={clip.image_prompt} className="object-cover"/>
          </div>
          <div className="flex flex-col relative font-bold border border-b-black px-5 py-3">
            <div className ="relative inline-block">
                <div className='inline-block p-2 font-black'>{clip.speaker}</div>
            </div>
            <HiPencil onClick={handleEditClick} className="absolute cursor-pointer right-3 w-9 h-9 p-1 bg-orange-200 rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none hover:no-underline hover:text-black"/>
            <MdDelete onClick={handleDeleteClick} className="absolute cursor-pointer left-3 w-9 h-9 p-1 bg-red-400 rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none hover:no-underline hover:text-black"/>
          </div>
          <div className="my-3">{clip.speech}</div>
          {/* Audio Element */}
          <div className="flex justify-center m-2">
            <audio key={clip.audio_url} controls className="rounded-md w-full border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
              <source src={clip.audio_url} type="audio/wav" />
              Your browser does not support the audio tag.
            </audio>
          </div>
        </div>
      </div>

      <Modal 
        active={editing} 
        setActive={handleCancelClick} 
      >
        <Tabs
          tabsArray={["image", "audio"]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        >
          <Tab tabName="image" activeTab={activeTab}>
            <h4 className="font-black text-2xl mt-2 text-center">Edit Image</h4>
            <div className="flex flex-col mx-1 my-2">
                <img src={editedClip.image_url} alt={editedClip.image_prompt} className="w-100 mx-auto rounded-md border-2 border-black border-solid shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]" style={{maxWidth: '16rem'}}/>
                {imageLoading && (
                  <img src={loading_animation} alt="Loading animation" className="w-[100px] mx-auto mt-1" />
                )}
                <label className="font-semibold m-1 mt-2">Image Prompt</label>
                <textarea
                  value={editedClip.image_prompt}
                  onChange={(e) => handleInputChange(e, 'image_prompt')}
                  className="h-[150px] w-full md:w-[265px] resize-vertical rounded-md border-2 border-black p-[10px] shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] outline-none transition-all focus:translate-x-[3px] focus:translate-y-[3px] focus:shadow-none"
                  rows="5"
                  disabled={imageLoading}
                />
                <Button text="Regenerate Image" disabled={imageLoading} onClick={handleRegenImage} width="w-100" color={`bg-green-400`} variant="regen" margin="mx-auto my-2" />
            </div>
          </Tab>
          <Tab tabName="audio" activeTab={activeTab}>
            <h4 className="font-black text-2xl mt-2 text-center">Edit Audio</h4>
            <div className="max-w-[265px]">
              <div className="flex flex-col mx-1 my-2">
                <label className="font-semibold m-1 mt-2">Audio</label>
                <audio controls key={editedClip.audio_url} className="rounded-md w-full border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
                  <source src={editedClip.audio_url} type="audio/wav"/>
                  Your browser does not support the audio tag.
                </audio>
                {voiceLoading && (
                  <img src={loading_animation} alt="Loading animation" className="w-[100px] mx-auto mt-1" />
                )}
              </div>
              <div className="flex flex-col mx-1 my-0">
                <label className="font-semibold m-1 mt-2">Speaker</label>
                <div className="relative inline-block text-left">
                  <Select items={script.characters} onChange={(value) => handleSelectChange(value, 'speaker')} value={editedClip.speaker} />
                </div>
              </div>
              <div className="flex flex-col mx-1 my-0">
                <label className="font-semibold m-1 mt-2">Speech</label>
                <textarea
                  type="text"
                  value={editedClip.speech}
                  onChange={(e) => handleInputChange(e, 'speech')}
                  className="h-[150px] w-full md:w-full resize-vertical rounded-md border-2 border-black p-[10px] shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] outline-none transition-all focus:translate-x-[3px] focus:translate-y-[3px] focus:shadow-none"
                  rows="5"
                  disabled={voiceLoading}
                />
              </div>
              <div className="flex flex-col mx-1 my-0">
                <Button text="Regenerate Audio" width="w-100" disabled={voiceLoading} onClick={handleRegenAudio} color={`bg-green-400`} variant="regen" margin="mx-auto my-2" />
              </div>
            </div>
          </Tab>
        </Tabs>
        <div className="flex flex-row md:flex-row items-center py-3 mt-1 bg-gray-100 w-full">
          <Button text="Cancel" onClick={handleCancelClick} color={`bg-stone-300`}/>
          <Button text="Save Clip" onClick={handleSaveClick} disabled={imageLoading || voiceLoading }/>
        </div>
      </Modal>

      {/* Delete Clip Modal */}
      <Modal active={showDeleteModal} setActive={() => setShowDeleteModal(false)} dialogClassName="rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
        <div className="text-xl font-bold mt-4">Delete This Clip?</div>
        <div className="p-4">
          Are you sure you want to delete this clip? This action cannot be undone.
        </div>
        <div className="w-100 flex flex-row justify-between gap-2 p-2">
          <Button text="Cancel" onClick={() => setShowDeleteModal(false)} color={`bg-stone-300`} />
          <Button text="Confirm" onClick={handleConfirmDelete} color={`bg-red-400`}/>
        </div>
      </Modal>
    </>
  );
};

export default ClipCard;