import React, {Children} from "react"

export default function Tab({tabName, activeTab, children}) {
    if (activeTab !== tabName) return null;
    return <>
        { activeTab === tabName && (
            <>
            {Children.map(children, (child, index) => child)}
            </>
        )}
    </>
}