import React from 'react';
import { IoIosArrowForward } from "react-icons/io";

const steps = ['script', 'images', 'audio', 'render'];

const ProgressBar = ({ status }) => {
  const getStatus = (step) => {
    if (status.includes(step)) {
      return 'in_progress';
    }

    const stepIndex = steps.indexOf(step);
    const previousSteps = steps.slice(0, stepIndex);

    for (let i = previousSteps.length - 1; i >= 0; i--) {
      const previousStep = previousSteps[i];
      if (status.includes(previousStep)) {
        return 'not_started';
      }
    }

    return 'completed';
  };

  const getStepColor = (stepStatus) => {
    switch (stepStatus) {
      case 'not_started':
        return 'bg-slate-300';
      case 'in_progress':
        return 'bg-orange-400';
      case 'completed':
        return 'bg-green-400';
      default:
        return 'bg-slate-300';
    }
  };

  const renderStep = (step) => {
    const stepStatus = getStatus(step);
    const stepStyle = {
      backgroundColor: getStepColor(stepStatus),
    };

    return (
      <div key={step} className={`inline-block text-xs md:text-base my-1 capitalize rounded-md shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] border-2 border-black px-3 py-2 ${stepStyle.backgroundColor}`}>
        {step} {stepStatus === 'in_progress' && <>&hellip;</>}
      </div>
    );
  };

  return (
    <div className="flex flex-row flex-wrap mx-auto justify-evenly gap-1 container items-center">
      {steps.map((step) => (
        <React.Fragment key={step}>
          {renderStep(step)}
          {step !== 'render' && <IoIosArrowForward  className="text-black ml-1" />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProgressBar;