import React, { useState, useEffect } from 'react';
import { getYtShorts } from './api.js';

const YouTubeShorts = () => {
  const [shorts, setShorts] = useState([]);

  useEffect(() => {
    async function loadShorts() {
      const shorts = await getYtShorts();
      setShorts(shorts);
    }

    loadShorts();
  }, []);

  return (
    <div className="bg-white rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] w-full">
      <div className="p-4 font-bold">Check out our YouTube Channel!</div>
      <div className="flex flex-row space-x-3 overflow-x-scroll">
        {shorts && (
          shorts.map(short => (
            <div key={short.id} className="pb-3 first:pl-3">
              <p className="truncate max-w-[315px]">{short.title}</p>
              <iframe width="315" height="560"
                src={`https://www.youtube.com/embed/${short.id}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"></iframe>
           </div>
          ))
        )}
        
      </div>
    </div>
  );
};

export default YouTubeShorts;