import React, { useState, useEffect } from 'react';

export default function RadioGroup({ items, value, onChange }: { items: string[], value: string, onChange: (value: string) => void }) {
  const [activeItem, setActiveItem] = useState<string | null>(null);

  useEffect(() => {
    setActiveItem(value);
  }, [value]);

  return (
    <div className="font-medium">
      {items.map((item) => {
        const isChecked = activeItem === item

        return (
          <button
            onClick={() => {
              setActiveItem(item);
              onChange(item);
            }}
            className="my-2 flex items-center"
            role="radio"
            aria-checked={isChecked}
            key={item}
          >
            <div className="mr-2.5 h-4 w-4 rounded-full bg-white p-1 outline outline-2 outline-black">
              {isChecked && (
                <div className="h-full w-full rounded-full bg-black"></div>
              )}
            </div>
            <p className="capitalize text-md">{item}</p>
          </button>
        )
      })}
    </div>
  )
}