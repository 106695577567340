import React, { useState, useEffect } from 'react';

export default function RadioGroupJson({
  items,
  value,
  onChange
}: {
  items: { title: string; model_token: string }[];
  value: string;
  onChange: (value: string) => void;
}) {
    const [activeItem, setActiveItem] = useState<string | null>(value || null);

    useEffect(() => {
      setActiveItem(value || null);
      onChange(value);
    }, [value, onChange]);

    const handleClick = (itemToken: string) => {
      setActiveItem(itemToken);
      onChange(itemToken);
    };

  return (
    <div className="p-1 font-medium overflow-y-auto max-h-40">
      {items.map((item, index) => {
        const isChecked = activeItem === item.model_token;

        return (
            <button
              onClick={() => handleClick(item.model_token)}
              className="my-2 flex items-center"
              role="radio"
              aria-checked={isChecked}
              key={item.model_token} 
            >
              <div className="mr-2.5 h-4 w-4 rounded-full bg-white p-1 outline outline-2 outline-black">
                {isChecked && (
                  <div className="h-full w-full rounded-full bg-black"></div>
                )}
              </div>
              <p className="capitalize text-md">{item.title}</p>
            </button>
          );
        })}
      </div>
    );
}