import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ImageCard from './Components/ImageCard.tsx';
import { IoClose } from "react-icons/io5";
import Modal from './Components/Modal.tsx';
import Button from './Components/Button';

export default function RecentVideos() {
  const [videos, setVideos] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [videoToRemove, setVideoToRemove] = useState("");

  useEffect(() => {
    // Fetch videos from localStorage
    const localStorageKey = 'scripts';
    const storedVideos = JSON.parse(localStorage.getItem(localStorageKey)) || [];
    
    // Sort videos based on createdAt in reverse order (most recent first)
    const sortedVideos = storedVideos.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    setVideos(sortedVideos);
  }, []);


  if (videos.length === 0) {
    return null;
  } 

  const handleDeleteClick = (title) => {
    setVideoToRemove(title);
    setShowDeleteModal(true);
  }

  const handleConfirmDelete = () => {
    // Fetch videos from local storage
    const localStorageKey = 'scripts';
    const storedVideos = JSON.parse(localStorage.getItem(localStorageKey)) || [];
    
    // Filter out the video to delete
    const updatedVideos = storedVideos.filter(video => video.title !== videoToRemove);

    // Update local storage with the updated videos
    localStorage.setItem(localStorageKey, JSON.stringify(updatedVideos));

    // Update state to reflect the change
    setVideos(updatedVideos);

    // Close the modal
    setShowDeleteModal(false);
  }
  
  return (
    <>
      <div className=" bg-white w-full border-2 p-2 my-3 border-black rounded-md shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
          <div className="font-bold text-left ml-3">
              Your Recently Viewed Videos
          </div>
          <div className="flex flex-row snap-x overflow-auto space-between">
              {videos.map((video) => (
                <div key={video.uuid} className="relative">
                  <Link to={`/video/${video.uuid}`} key={video.uuid}>
                    <ImageCard
                        title={video.title}
                        uuid={video.uuid}
                        imageUrl={video.imageUrl}
                        variant={"button"}
                    >            
                      <div className="text-left truncate">
                          {video.title}
                      </div>
                    </ImageCard>
                  </Link>
                  <IoClose onClick={() => handleDeleteClick(video.title)} className="absolute cursor-pointer top-1 right-1 w-9 h-9 p-1 bg-red-400 rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none hover:no-underline hover:text-black"/>
                </div>
              ))}
          </div>
      </div>

      {/* Delete Clip Modal */}
      <Modal active={showDeleteModal} setActive={() => setShowDeleteModal(false)} dialogClassName="rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
        <div className="text-xl font-bold mt-4">Remove Video?</div>
        <div className="p-4">
          Are you sure you want to remove "{videoToRemove}" from your recently viewed videos?
        </div>
        <div className="w-100 flex flex-row justify-between gap-2 p-2">
          <Button text="Cancel" onClick={() => setShowDeleteModal(false)} color={`bg-stone-300`} />
          <Button text="Confirm" onClick={handleConfirmDelete} color={`bg-red-400`}/>
        </div>
      </Modal>
    </>
  )
}



