import React from 'react';

type Props = {
    imageUrl: string
    children: React.ReactNode
    variant: string
    title: string
  }
  
  export default function ImageCard({ imageUrl, children, title, variant }: Props) {
    return (
      <figure className={`w-[200px] overflow-hidden snap-center rounded-md border-2 m-2 border-black bg-fuchsia-300 font-semibold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]
        ${variant === 'button' && 'transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none hover:no-underline hover:text-black'}`
      }>
        <img className="w-full max-h-[200px] object-cover" src={imageUrl} alt={title} />
        <figcaption className="border-t-2 border-black p-4 truncate">
          {children}
        </figcaption>
      </figure>
    )
  }
  