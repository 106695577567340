import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaQuestion } from "react-icons/fa";
import Drawer from './Components/Drawer.tsx';
import logo from './Logo.png';
import icon from './Icon.png';

function Navbar({ children, title }) {
  const [isDrawerActive, setIsDrawerActive] = useState(false)

  const isMobileView = () => {
    return window.innerWidth < 768; 
  };
  // from-red-300 via-purple-200 to-sky-200
  // from-red-300 via-orange-200 to-yellow-200
  // from-red-200 to-red-300
  // from-blue-200 to-cyan-200
  // from-cyan-500 to-pink-500

  return (
    <nav className="left-0 top-0 z-10 mx-auto flex h-[66px] md:h-[88px] w-full items-center border-b-4 border-black bg-gradient-to-r from-red-200 to-sky-200 md:from-red-300  md:via-purple-200 md:to-sky-200 px-5 ">
      <div className="mx-auto flex w-[1300px] max-w-full items-center justify-between">

        <Link  to={'/'}>
          {isMobileView() ? 
            <img src={icon} className="mx-auto p-2 h-[70px]" alt="logo" />
            :
            <img src={logo} className="mx-auto p-2 h-[75px]" alt="logo" />
          }   
        </Link>

        <button
          onClick={() => setIsDrawerActive(true)}
          className="flex items-center bg-white justify-center rounded-md border-2 border-black p-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none"
        >
          <FaQuestion className="h-5 w-5 md:h-6 md:w-6" />
        </button>
        <Drawer active={isDrawerActive} setActive={setIsDrawerActive} title={title}>
          <div className="h-full w-full">
            {children}  
          </div>
        </Drawer>
      </div>
    </nav>
  )
}

export default Navbar;