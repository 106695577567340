import React, { useState, useMemo, useEffect } from 'react';
import { IoIosAdd } from "react-icons/io";
import loading_animation from "./loading_animation.gif";
import { useParams } from "react-router-dom";
import { regenerateVoiceClip, getJobStatus } from './api';
import RadioGroupJson from "./Components/RadioGroupJson.tsx";
import Input from './Components/Input.tsx';
import Modal from './Components/Modal.tsx';
import AudioButton from './Components/AudioButton';
import Button from './Components/Button';

const AddCharacter = ( {voiceList, onAddCharacter} ) => {
    const { uuid } = useParams();
    const [newCharacter, setNewCharacter] = useState({
        name: '',
        voice_token: '',
    });
    const [voiceLoading, setVoiceLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [voiceName, setVoiceName] = useState("");
    const [selectedVoice, setSelectedVoice] = useState("");
    const [userInput, setUserInput] = useState(`Hey, it's me. This is sample audio.`);
    const [audioSample, setAudioSample] = useState(null);

    const poll_delay_ms = 6000;
    const max_polls = 20;
    const backoff_factor = 1.2;

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        // Reset newClip state when the modal is closed
        setNewCharacter({
            name: '',
            voice_token: '',
        });
        setSelectedVoice("");
        setUserInput(`Hey, it's me. This is sample audio.`);
        setAudioSample(null);
        setVoiceName("");
    };

    const filteredResults = useMemo(() => {
        if (!voiceName.trim()) {
            return [];
        }
    
        const lowerCaseInput = voiceName.toLowerCase();
        return voiceList.filter(model =>
          model.title.toLowerCase().includes(lowerCaseInput)
        ).slice(0, 20); // Display only the top 20 matches
    }, [voiceName, voiceList]);

    const handleSampleAudio = async () => {
        console.log(selectedVoice);
        setAudioSample(null);
        if(!selectedVoice) return;
        setVoiceLoading(true);
        const jobId = await regenerateVoiceClip(uuid, userInput, selectedVoice);
        setTimeout(() => pollVoice(jobId), poll_delay_ms);
    }

    useEffect(() => {
        setNewCharacter(prevCharacter => ({ ...prevCharacter, voice_token: selectedVoice }));
    }, [selectedVoice]);

    const pollVoice = async (jobId, attempt=1) => {
        console.log('Polling voice job', jobId);
        const res = await getJobStatus(jobId, 'voice');
        if(res.status === 'success'){
          console.log('Voice job complete', res);
          setAudioSample(res.audio_url);
          setVoiceLoading(false);
        }
        else if(res.status === 'failed'){
          console.error('Voice job failed', res);
          setVoiceLoading(false);
          // TODO: handle error
        }
        else{
          if(attempt >= max_polls){
            console.error('Voice job polling limit exceeded');
            setVoiceLoading(false);
          }
          else {
            setTimeout(() => pollVoice(jobId, attempt+1), poll_delay_ms * Math.pow(backoff_factor, attempt));
          }
        }
    };

    const handleAddCharcter = () => {
        onAddCharacter(newCharacter);
        handleCloseModal();
    }

    return (
        <>
            <div className="flex text-center mt-2 items-center mx-auto w-full md:w-80">
                <div className="flex-grow px-3">
                    <hr className="border-t border-black" />
                </div>
                <span className="">
                    <IoIosAdd onClick={handleOpenModal} className="cursor-pointer mx-auto bg-blue-300 w-9 h-9 p-1 rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none hover:no-underline hover:text-black"/>
                </span>
                <div className="flex-grow px-3">
                    <hr className="border-t border-black" />
                </div>
            </div>

            <Modal 
                active={showModal} 
                setActive={() => setShowModal(false)} 
                dialogClassName="rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
            >
                <div className="text-xl font-bold mt-4">Add A Character</div>
                <div className="max-w-[360px] p-4 w-full">
                    <div className="flex flex-col mx-1 my-0">
                        <label className="font-semibold m-1 mt-2">Character Name</label>
                        <div className="relative inline-block text-left">
                            <div className="flex flex-row flex-wrap items-center">
                                <Input value={newCharacter.name} setValue={(value) => setNewCharacter({ ...newCharacter, name: value })} />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col mx-1 my-0">
                        <label className="font-semibold m-1 mt-2">Look Up a Voice</label>
                        <div className="relative inline-block text-left">
                            <div className="flex flex-row flex-wrap items-center">
                                <Input value={voiceName} setValue={setVoiceName} />
                            </div>
                        </div>
                    </div>
                    {filteredResults.length > 0 && (
                        <div>
                            <div className="font-semibold m-1 mt-2">
                                Select a Voice Model
                            </div>
                            <div className="truncate max-h-[300px] bg-green-200 max-w-[340px] rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] ">
                                <RadioGroupJson
                                    items={filteredResults} 
                                    value={selectedVoice}
                                    onChange={(value) => {
                                        setSelectedVoice(value)
                                        console.log("Selected Voice:", selectedVoice)}}
                                />
                            </div>
                            
                        </div>
                        
                    )}
                    <div className="mt-2">
                        <div className="font-semibold m-1 mt-2">
                            Choose Sample Audio
                        </div>
                        <textarea
                            type="text"
                            className="min-h-[100px] w-full resize-y required:border-red-500 rounded-md border-2 border-black p-[10px] font-bold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] outline-none transition-all focus:translate-x-[3px] focus:translate-y-[3px] focus:shadow-none"
                            value={userInput}
                            onChange={(e) => {setUserInput(e.target.value)}}
                            placeholder={userInput}
                        ></textarea>
                    </div>
                    <Button text="Generate Sample" width="w-100" disabled={voiceLoading} onClick={handleSampleAudio} color={`bg-green-400`} variant="regen" margin="my-2" />
                    {voiceLoading && (
                        <img src={loading_animation} alt="Loading animation" className="w-[100px] mx-auto mt-1" />
                    )}
                    {audioSample && (
                        <div className="flex flex-row mx-1 mt-3">
                            <div className="font-semibold m-1">
                                Play Voice Sample: 
                            </div>
                            <div className="ml-2">
                                <AudioButton audioSrc={audioSample} />
                            </div>
                        </div>
                    )}
                </div>
                <div className="w-100 flex flex-row justify-between gap-2 p-2">
                    <Button text="Cancel" onClick={handleCloseModal} color={`bg-stone-300`} />
                    <Button text="Add Character" onClick={handleAddCharcter} color={`bg-cyan-300`} disabled={voiceLoading}/>
                </div>
            </Modal>
        </>
    )
}

export default AddCharacter;