import React, { useState, useRef } from 'react';
import { HiSpeakerWave, HiSpeakerXMark } from "react-icons/hi2";

const AudioButton = ({ audioSrc }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(new Audio(audioSrc));

  const handleTogglePlay = () => {
    const audio = audioRef.current;

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }

    setIsPlaying(!isPlaying);

    audio.onended = () => {
        setIsPlaying(false);
    };
  };

  return (
    <button onClick={handleTogglePlay} className="cursor-pointer w-9 h-9 bg-blue-200 rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none hover:no-underline hover:text-black">
      {isPlaying ? <HiSpeakerXMark className="mx-auto" /> : <HiSpeakerWave className="mx-auto" />}
    </button>
  );
};

export default AudioButton;