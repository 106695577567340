import React from 'react';
import { FaRedoAlt } from "react-icons/fa";

const Button = ({ text, style, onClick, color="bg-cyan-300", margin="mx-auto", disabled, variant, width="" }) => {
    return (
        <button 
            className={`${width} flex cursor-pointer items-center rounded-md border-2 disabled:bg-slate-100 disabled:translate-none disabled:cursor-wait disabled:shadow-none disabled:transition-none disabled:text-slate-500 border-black px-4 md:px-10 py-3 font-bold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none hover:no-underline hover:text-black mb-2 ${style} ${color} ${margin}`}
            onClick={onClick}
            disabled={disabled}
        >
            {variant === 'regen' ? <><FaRedoAlt /> &nbsp; {text}</>  : text}
        </button>
    );
};

export default Button;