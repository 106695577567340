import React, { Children } from 'react';

type Props = {
  tabsArray: string[]
  activeTab: string
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
  children: React.ReactNode
}

export default function Tabs({ tabsArray, activeTab, setActiveTab, children }: Props) {
  return (
    <>
      <div className="flex flex-row justify-center">
        {tabsArray.map((tab, index) => {
          return (
            <button
              key={index}
              onClick={() => setActiveTab(tab)}
              className={`${activeTab === tab ? 'bg-[#b46ed4] hover:transition-none hover:translate-none' : 'bg-[#d4b1FF] shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none'}  mx-1 mt-2 capitalize z-50 cursor-pointer transition-all border-2 border-black px-4 py-2 text-center font-bold rounded-md`}
            >
              {tab}
            </button>
          )
        })}
      </div>
      {Children.map(children, (child, index) => child)}
    </>
  )
}
