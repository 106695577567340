import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Button from "./Components/Button";
import "./App.css";
import { getScript, handleSave } from "./api";
import Header from "./Header";
import Characters from "./Characters";
import ClipCard from "./ClipCard";
import { useNavigate } from "react-router-dom";
import Accordion from "./Components/Accordion.tsx";
import Modal from "./Components/Modal.tsx";
import Navbar from "./Navbar";
import AddClip from "./AddClip";
import { HiPencil } from "react-icons/hi";
import Input from "./Components/Input.tsx";
import loading_animation from "./loading_animation.gif";
import { regenerateVoiceClip, getJobStatus } from "./api";
import ToggleSwitch from "./Components/ToggleSwitch.tsx";

export default function Edit() {
  const { uuid } = useParams();
  const navigate = useNavigate();

  //Get the script from the checkstatus API
  const [script, setScript] = useState(null);
  const [status, setStatus] = useState("loading");
  const [showModal, setShowModal] = useState(false);
  const [showTitleModal, setShowTitleModal] = useState(false);
  const [title, setTitle] = useState("");
  const completeFromStorage =
    sessionStorage.getItem("completeStatus") === "complete";
  const complete =
    completeFromStorage ||
    (status || "generating").toLowerCase().includes("complete");
  const [oldAudioUrls, setOutdatedAudioUrls] = useState([]);

  const poll_delay_ms = 6000;
  const max_polls = 20;
  const backoff_factor = 1.2;

  useEffect(() => {
    if (complete) {
      sessionStorage.setItem("completeStatus", "complete");
    } else {
      sessionStorage.removeItem("completeStatus");
    }
  }, [complete]);

  useEffect(() => {
    async function loadScript() {
      const storedScript = sessionStorage.getItem(`editedScript-${uuid}`);

      if (storedScript) {
        const parsedScript = JSON.parse(storedScript);

        // Set a default value for status if it is undefined
        const scriptWithStatus = {
          ...parsedScript,
          status: parsedScript.status || "loading",
        };

        setScript(scriptWithStatus);
      } else {
        const res = await getScript(uuid);
        const script = res.script;
        setStatus(res.status);
        setScript(script);
        setTitle(script.metadata.title);
      }
    }

    loadScript();
  }, [uuid]);

  // Save the edited script to sessionStorage
  useEffect(() => {
    if (
      script &&
      script.status &&
      script.status.toLowerCase().includes("complete")
    ) {
      sessionStorage.setItem(`editedScript-${uuid}`, JSON.stringify(script));
    }
  }, [script, uuid]);

  const handleDeleteClip = (index) => {
    // Remove the clip from the script
    const newScript = { ...script };
    newScript.clips.splice(index, 1);
    setScript(newScript);

    // Save the updated script to sessionStorage
    sessionStorage.setItem(`editedScript-${uuid}`, JSON.stringify(newScript));
  };

  const handleAddClip = (newClip, clipIndex) => {
    const newClips = [
      ...script.clips.slice(0, clipIndex),
      newClip,
      ...script.clips.slice(clipIndex),
    ];

    setScript({ ...script, clips: newClips });

    // Save the updated script to sessionStorage
    sessionStorage.setItem(
      `editedScript-${uuid}`,
      JSON.stringify({ ...script, clips: newClips })
    );
  };

  const handleAddCharacter = (newCharacter) => {
    const newCharacters = [...script.characters, newCharacter];

    setScript({ ...script, characters: newCharacters });

    // Save the updated script to sessionStorage
    sessionStorage.setItem(
      `editedScript-${uuid}`,
      JSON.stringify({ ...script, characters: newCharacters })
    );
  };

  const handleEditClip = (editedClip, index) => {
    const updatedScript = {
      ...script,
      clips: script.clips.map((clip, i) => (i === index ? editedClip : clip)),
    };

    // Update the script state
    setScript(updatedScript);

    // Save the updated script to sessionStorage
    // Use the updatedScript variable instead of script
    sessionStorage.setItem(
      `editedScript-${uuid}`,
      JSON.stringify(updatedScript)
    );
  };

  const handleEditTitle = () => {
    const updatedScript = {
      ...script,
      metadata: {
        ...script.metadata,
        title: title,
      },
    };

    // Update the script state
    setScript(updatedScript);

    // Save the updated script to sessionStorage
    sessionStorage.setItem(
      `editedScript-${uuid}`,
      JSON.stringify(updatedScript)
    );

    // Close the title modal
    setShowTitleModal(false);
  };

  const handleConfirm = async () => {
    try {
      const newUuid = await handleSave(uuid, script);
      if (newUuid !== null) {
        setShowModal(false);
        console.log("Video regeneration request succeeded.");
        navigate(`/video/${newUuid}`);
      } else {
        console.error("Video regeneration request failed.");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleReplaceVoice = async (editedCharacter) => {
    setScript({
      ...script,
      characters: script.characters.map((character) =>
        character.name === editedCharacter.name ? editedCharacter : character
      ),
    });

    const clipsOfCharacter = script.clips.filter(
      (clip) => clip.speaker === editedCharacter.name
    );
    setOutdatedAudioUrls(clipsOfCharacter.map((clip) => clip.audio_url));

    // deliberately using a normal for loop here to avoid async issues
    for (const clip of clipsOfCharacter) {
      const jobId = await regenerateVoiceClip(
        script.metadata.root_script_id || script.id,
        clip.speech,
        editedCharacter.voice_token
      );
      setTimeout(() => pollVoice(jobId, clip), poll_delay_ms);
    }
  };

  const unfinishedOutdatedAudioUrls =
    script?.clips?.filter((clip) => oldAudioUrls.includes(clip.audio_url)) ??
    [];

  const bulkAudioRegenerationInProgress = oldAudioUrls.length > 0;

  // every time the script clips update, check if all the audio urls have been updated
  useEffect(() => {
    if (unfinishedOutdatedAudioUrls.length === 0) {
      console.log("All audio urls have been updated");
      setOutdatedAudioUrls([]);
    }
  }, [unfinishedOutdatedAudioUrls.length]);

  const pollVoice = async (jobId, clipToUpdate, attempt = 1) => {
    console.log("Polling voice job", jobId);
    const res = await getJobStatus(jobId, "voice");
    if (res.status === "success") {
      console.log("Voice job complete", res);
      // Update the specific clip's audio_url within the updatedScript
      updateAudioUrlInScript(clipToUpdate, res.audio_url);
    } else if (res.status === "failed") {
      console.error("Voice job failed", res);

      // TODO: handle error
    } else {
      if (attempt >= max_polls) {
        console.error("Voice job polling limit exceeded");
      } else {
        // Exponential backoff with jitter
        setTimeout(
          () => pollVoice(jobId, clipToUpdate, attempt + 1),
          poll_delay_ms * Math.pow(backoff_factor, attempt) +
            Math.random() * 1000
        );
      }
    }
  };

  const updateAudioUrlInScript = (clipToUpdate, newAudioUrl) => {
    setScript((prevScript) => {
      const updatedScriptCopy = { ...prevScript };
      const updatedClipIndex = updatedScriptCopy.clips.findIndex(
        (clip) => clip.audio_url === clipToUpdate.audio_url
      );
      updatedScriptCopy.clips[updatedClipIndex].audio_url = newAudioUrl;
      return updatedScriptCopy;
    });
  };

  if (!script)
    return (
      <>
        <Header title="Loading your script..." />
        <div>
          {/* Display loading indicator */}
          <p>Please wait while we load your script.</p>
        </div>
      </>
    );

  return (
    <>
      <Navbar title="Editing Your Video">
        <div>
          <div className="font-semibold mt-2">Characters</div>
          <div className="text-sm space-y-2">
            <p>
              This is a list of characters that were suggested for your script.
              Though they may not all be in the video, you can include them by
              editing a clip and choosing their name from the list of
              characters.
            </p>
            <p>
              You can add characters by clicking the "+" icon. Enter a name for
              the characters, and look up available voices. You can generate a
              sample to see the quality of voice.
            </p>
          </div>
          <div className="font-semibold mt-2">Clips</div>
          <div className="text-sm space-y-2">
            <p>
              Each clip contains the functionality to edit the image, text, and
              audio. While you're regenerating the audio or image, be sure to
              wait for the new content to appear before trying again.
            </p>
          </div>
          <div className="font-semibold mt-2">Inserting A Clip</div>
          <div className="text-sm space-y-2">
            <p>
              Press the blue "+"" button between each clip to insert a clip at
              that position.
            </p>
          </div>
          <div className="font-semibold mt-2">Regenerating</div>
          <div className="text-sm space-y-2">
            <p>
              Anywhere you see the pencil symbol, you can edit that particular
              piece.
            </p>
            <p>
              When you have finished editing your video, be sure to click the
              "Regenerate" button at the top of the page. This will initiate the
              creation of a whole new video with your edits.
            </p>
            <p>
              Your original video still exists at the same link. The new video
              will have an entirely new link.
            </p>
          </div>
        </div>
      </Navbar>
      {complete ? (
        <>
          <div className="flex flex-row items-center">
            <Header
              title={script?.metadata?.title || "Loading your script..."}
            />
            <HiPencil
              onClick={() => {
                setShowTitleModal(true);
              }}
              className="cursor-pointer w-9 h-9 p-1 mt-1 bg-orange-200 rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none hover:no-underline hover:text-black"
            />
          </div>

          {/* Save button to trigger the save function */}
          <div className="flex flex-row sticky top-0 bg-yellow_background-500/[0.85] w-full p-3 z-40">
            <div className="gap-2 mx-auto flex flex-row">
              <Link
                to={`/video/${uuid}`}
                className="hover:no-underline right-0"
              >
                <Button
                  text="Cancel"
                  onClick={() => {
                    sessionStorage.removeItem(`editedScript-${uuid}`);
                  }}
                  style={`px-4`}
                  color={`bg-stone-300`}
                  margin={`mr-0`}
                />
              </Link>
              <Button
                text="Regenerate"
                onClick={() => {
                  setShowModal(true);
                }}
              />
            </div>
          </div>
          <main>
            <div className="container">
              <div>
                <div className="mb-4">
                  {complete && (
                    <>
                      {/* Display the characters as a list of cards */}
                      <Characters
                        script={script}
                        onAcceptVoice={handleReplaceVoice}
                        onAddCharacter={handleAddCharacter}
                      />
                      <div className="flex flex-row bg-emerald-300 mx-auto max-w-[360px] items-start space-between gap-4 rounded-md shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] border-2 border-black px-4 py-2 my-3">
                        <div className="font-semibold my-auto text-left">
                          Randomize Background Music
                        </div>
                        <div className="my-auto mt-1 ">
                          <ToggleSwitch
                            question="Randomize BGM"
                            checked={script.metadata.randomize_bgm}
                            onToggle={(checked) => {
                              setScript({
                                ...script,
                                metadata: {
                                  ...script.metadata,
                                  randomize_bgm: checked,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                      <Accordion
                        question="Video Prompt"
                        answer={script.metadata.prompt}
                      />
                      <div className="w-full relative m-auto">
                        <AddClip
                          script={script}
                          onAddClip={handleAddClip}
                          clipIndex={0}
                        />
                        {script.clips?.map((clip, index) => (
                          <React.Fragment key={index}>
                            <ClipCard
                              index={index}
                              clip={clip}
                              script={script}
                              onEdit={(editedClip, index) =>
                                handleEditClip(editedClip, index)
                              }
                              onDelete={handleDeleteClip}
                            />
                            <AddClip
                              script={script}
                              onAddClip={handleAddClip}
                              clipIndex={index + 1}
                            />
                          </React.Fragment>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </main>
        </>
      ) : (
        <Header title="Script has not finished generating." />
      )}

      <Modal
        active={showModal}
        setActive={() => setShowModal(false)}
        dialogClassName="rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
      >
        <div className="text-xl font-bold mt-4">You Sure About That?</div>
        <div className="p-4">
          Are you sure you're done editing this video and are ready to
          regenerate it? This will end the editing process and take you back to
          the loading screen.
        </div>
        <div className="w-100 flex flex-row justify-between gap-2 p-2">
          <Button
            text="Cancel"
            onClick={() => setShowModal(false)}
            color={`bg-stone-300`}
          />
          <Button text="Confirm" onClick={handleConfirm} />
        </div>
      </Modal>

      <Modal
        active={showTitleModal}
        setActive={() => setShowTitleModal(false)}
        dialogClassName="rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
      >
        <div className="text-xl font-bold mt-4">Edit Title</div>
        <div className="relative inline-block p-2 text-left">
          <div className="w-full">
            <Input value={title} setValue={setTitle} />
          </div>
        </div>
        <div className="w-100 flex flex-row justify-between gap-2 p-2">
          <Button
            text="Cancel"
            onClick={() => setShowTitleModal(false)}
            color={`bg-stone-300`}
          />
          <Button text="Confirm" onClick={handleEditTitle} />
        </div>
      </Modal>

      <Modal
        active={bulkAudioRegenerationInProgress}
        closeable={false}
        dialogClassName="rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] z-50"
      >
        <div className="text-xl font-bold mt-4">Audio Regenerating</div>
        <div className="relative inline-block p-2 text-left">
          <div className="w-full">
            Regenerating audio for {unfinishedOutdatedAudioUrls.length} more
            clip{unfinishedOutdatedAudioUrls.length > 1 ? "s" : ""} out of{" "}
            {oldAudioUrls.length}. Please wait...
          </div>
          <img
            src={loading_animation}
            alt="Loading animation"
            className="w-[100px] mx-auto mt-1"
          />
        </div>
      </Modal>
    </>
  );
}
