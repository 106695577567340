import React, { useState } from 'react';
import { HiOutlinePaperAirplane } from "react-icons/hi2";

const ShareButton = ({ url, title }) => {
  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Copy the URL to the clipboard
    navigator.clipboard.writeText(currentUrl)
      .then(() => {
        // Set copied to true if successful
        setCopied(true);
        // Reset copied state after 1.5 seconds
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      })
      .catch((error) => {
        console.error('Failed to copy to clipboard', error);
      });
  };
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          url: url,
        });
        console.log('Share was successful.');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      console.log('Web Share API not supported.');
      copyToClipboard();
    }
  };

  return (
    <>
      <HiOutlinePaperAirplane className="cursor-pointer w-10 h-10 p-1 md:w-12 md:h-12 md:p-2 -rotate-45 bg-cyan-300 rounded-full border-2 border-black shadow-[0px_4px_0px_0px_rgba(0,0,0,1)] transition-all hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none hover:no-underline hover:text-black" onClick={handleShare} />
      {copied && <div className="text-sm font-semibold absolute bg-neutral-100 p-1 rounded-md border-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] border-black top-10 right-4 z-10">Link Copied!</div>}
    </>
  );
};

export default ShareButton;