import { v4 as uuidv4 } from "uuid";
const baseUrl = "https://nfsmntamxe.execute-api.us-east-1.amazonaws.com/Prod";

export const generateVideo = async (text, genre, orientation) => { 
  try {
    const uuid = uuidv4();
    await fetch(`${baseUrl}/video/generate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ prompt: text , id : uuid , genre: genre, orientation: orientation }),
    });
    return uuid;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

export const getScript = async (id) => {
  try {
    const response = await fetch(`${baseUrl}/video/${id}`);
    if (response.ok) {
      try {
        const data = await response.json();
        console.log("Script data:", data);
        return data;
      } catch (error) {
        // probably because the S3 path doesn't exist yet
        console.log("Probably benign error: " + error);
        return null;
      }
    } else {
      console.error(
        "Error fetching data from Video Poll API:",
        response.statusCode
      );
      return null;
    }
  } catch (error) {
    console.error("Error fetching data from Video Poll API:", error);
    return null;
  }
};

export const addAudioToClip = async (videoId, clipIndex, audioUrl) => {
  try {
    const response = await fetch(`${baseUrl}/video/${videoId}/clip/${clipIndex}/add-audio`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url: audioUrl }),
    });
    if (response.ok) {
      const data = await response.json();
      console.log(`Audio added to video ${data.script}, clip ${data.clip} url ${data.url}`);
      return data;
    } else {
      console.error(
        "Error adding audio to clip:",
        response.statusCode
      );
    }
  }
  catch(error) {
    console.error("Error adding audio to clip:", error);
  }
}

export const handleSave = async (id, script) => {
    try {
        // Perform the API request to save the script
        console.log('Saving script:', script);
        const response = await fetch(`${baseUrl}/video/${script.metadata.root_script?? id}/create-variant`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ script }),
        });
        if (response.ok) {
            const data = await response.json();
            return data.variant_id;
        } else {
            console.error('Failed to save script:', response.statusText);
            return null;
        }
        } catch (error) {
        console.error('Error saving script:', error);
    }
};

export const createScratchVideo = async (script) => {
  try {
    console.log("Creating scratch video:", script);
    const response = await fetch(`${baseUrl}/video/create`, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ script }),
    });
    if (response.ok) {
      const data = await response.json();
      console.log(data);
      return data.variant_id;
    } else {
      console.error('Failed to create scratch video:', response.statusText);
      return null;
    }
  } catch (error) {
    console.error('Error creating scratch video:', error);
  }
};

export const regenerateVoiceClip = async (rootScriptId, speech, voiceToken) => {
  try {
    const response = await fetch(`${baseUrl}/video/${rootScriptId}/regenerate-voice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ speech, voice_token: voiceToken }),
    });
    if (response.ok) {
      const data = await response.json();
      console.log("Regenerated voice clip response:", data);
      return data.job_id;
    } else {
      console.error("Error regenerating voice clip:", response.statusCode);
    }
  } catch (error) {
    console.error("Error regenerating voice clip:", error);
  }
}

export const regenerateImage = async (rootScriptId, prompt, fromScratch = false, orientation = undefined) => {
  try {
    const response = await fetch(`${baseUrl}/video/${rootScriptId}/regenerate-image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        image_prompt: prompt,
        root_script_id: rootScriptId,
        ...(fromScratch ? { orientation: orientation || "portrait" } : {}),
      }),
    });
    if (response.ok) {
      const data = await response.json();
      console.log("Regenerated image response:", data);
      return data.job_id;
    } else {
      console.error("Error regenerating image:", response.statusCode);
    }
  } catch (error) {
    console.error("Error regenerating image:", error);
  }
}

export const getJobStatus = async (jobId, jobType) => {
  try {
    const response = await fetch(`${baseUrl}/job/${jobType}/${jobId}`);
    if (response.ok) {
      const data = await response.json();
      console.log("Job status:", data);
      return data;
    } else {
      console.error("Error fetching job status:", response.statusCode);
    }
  } catch (error) {
    console.error("Error fetching job status:", error);
  }
}

export const getGenres = async() => {
  try {
    const response = await fetch(`${baseUrl}/genre`);
    if (response.ok) {
      const data = await response.json();
      console.log("Genres:", data);
      return data;
    } else {
      console.error("Error fetching genres:", response.statusCode);
    }
  } catch (error) {
    console.error("Error fetching genres:", error);
  }
}

export const getVoiceList = async() => {
  try {
    const response = await fetch(`${baseUrl}/fakeyou/characters`);
    if (response.ok) {
      const data = await response.json();
      console.log("Voice list:", data);
      return data;
    } else {
      console.error("Error fetching voice list:", response.statusCode);
    }
  } catch (error) {
    console.error("Error fetching voice list:", error);
  }
}

export const getYtShorts = async() => {
  try {
    const response = await fetch(`${baseUrl}/youtube/shorts`);
    if (response.ok) {
      const data = await response.json();
      console.log("YouTube Shorts:", data);
      return data;
    } else {
      console.error("Error fetching YouTube Shorts:", response.statusCode);
    }
  } catch (error) {
    console.error("Error fetching YouTube Shorts:", error);
  }
}