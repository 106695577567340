import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Header from "./Header";
import Characters from "./Characters";
import { regenerateVoiceClip, getJobStatus, createScratchVideo } from "./api";
import Modal from "./Components/Modal.tsx";
import loading_animation from "./loading_animation.gif";
import AddClip from "./AddClip";
import ClipCard from "./ClipCard";
import Button from "./Components/Button";

export default function Create() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [script, setScript] = useState({
    characters: [],
    clips: [],
    id: uuid,
    metadata: {
      art_style: "",
      bgm_style: "",
      bgm_url: "",
      date_created: "",
      genre: "scratch",
      orientation: "portrait",
      prompt: "I made this video from scratch!",
      title: "Scratch Video",
    },
    video_url: "",
  });
  const [title, setTitle] = useState(script.metadata.title);
  const [prompt, setPrompt] = useState(script.metadata.prompt);
  const [oldAudioUrls, setOutdatedAudioUrls] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const poll_delay_ms = 6000;
  const max_polls = 20;
  const backoff_factor = 1.2;

  useEffect(() => {
    async function loadScript() {
      const storedScript = sessionStorage.getItem(`editedScript-${uuid}`);

      if (storedScript) {
        const parsedScript = JSON.parse(storedScript);
        setScript(parsedScript);
        setTitle(parsedScript.metadata.title);
        setPrompt(parsedScript.metadata.prompt);
      }
    }

    loadScript();
  }, [uuid]);

  // Handle title change
  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    const updatedScript = {
      ...script,
      metadata: {
        ...script.metadata,
        title: newTitle,
      },
    };

    // Update the script state
    setScript(updatedScript);

    // Save the updated script to sessionStorage
    sessionStorage.setItem(
      `editedScript-${uuid}`,
      JSON.stringify(updatedScript)
    );
  };

  // Handle prompt change
  const handlePromptChange = (e) => {
    const newPrompt = e.target.value;
    setPrompt(newPrompt);
    const updatedScript = {
      ...script,
      metadata: {
        ...script.metadata,
        prompt: newPrompt,
      },
    };

    // Update the script state
    setScript(updatedScript);

    // Save the updated script to sessionStorage
    sessionStorage.setItem(
      `editedScript-${uuid}`,
      JSON.stringify(updatedScript)
    );
  };

  const handleDeleteClip = (index) => {
    // Remove the clip from the script
    const newScript = { ...script };
    newScript.clips.splice(index, 1);
    setScript(newScript);

    // Save the updated script to sessionStorage
    sessionStorage.setItem(`editedScript-${uuid}`, JSON.stringify(newScript));
  };

  const handleAddClip = (newClip, clipIndex) => {
    const newClips = [
      ...script.clips.slice(0, clipIndex),
      newClip,
      ...script.clips.slice(clipIndex),
    ];

    setScript({ ...script, clips: newClips });

    // Save the updated script to sessionStorage
    sessionStorage.setItem(
      `editedScript-${uuid}`,
      JSON.stringify({ ...script, clips: newClips })
    );
  };

  const handleAddCharacter = (newCharacter) => {
    const newCharacters = [...script.characters, newCharacter];

    setScript({ ...script, characters: newCharacters });

    // Save the updated script to sessionStorage
    sessionStorage.setItem(
      `editedScript-${uuid}`,
      JSON.stringify({ ...script, characters: newCharacters })
    );
  };

  const handleEditClip = (editedClip, index) => {
    const updatedScript = {
      ...script,
      clips: script.clips.map((clip, i) => (i === index ? editedClip : clip)),
    };

    // Update the script state
    setScript(updatedScript);

    // Save the updated script to sessionStorage
    // Use the updatedScript variable instead of script
    sessionStorage.setItem(
      `editedScript-${uuid}`,
      JSON.stringify(updatedScript)
    );
  };

  const handleReplaceVoice = async (editedCharacter) => {
    setScript({
      ...script,
      characters: script.characters.map((character) =>
        character.name === editedCharacter.name ? editedCharacter : character
      ),
    });

    const clipsOfCharacter = script.clips.filter(
      (clip) => clip.speaker === editedCharacter.name
    );
    setOutdatedAudioUrls(clipsOfCharacter.map((clip) => clip.audio_url));

    // deliberately using a normal for loop here to avoid async issues
    for (const clip of clipsOfCharacter) {
      const jobId = await regenerateVoiceClip(
        script.metadata.root_script_id || script.id,
        clip.speech,
        editedCharacter.voice_token
      );
      setTimeout(() => pollVoice(jobId, clip), poll_delay_ms);
    }
  };

  const unfinishedOutdatedAudioUrls =
    script?.clips?.filter((clip) => oldAudioUrls.includes(clip.audio_url)) ??
    [];

  const bulkAudioRegenerationInProgress = oldAudioUrls.length > 0;

  // every time the script clips update, check if all the audio urls have been updated
  useEffect(() => {
    if (unfinishedOutdatedAudioUrls.length === 0) {
      console.log("All audio urls have been updated");
      setOutdatedAudioUrls([]);
    }
  }, [unfinishedOutdatedAudioUrls.length]);

  const pollVoice = async (jobId, clipToUpdate, attempt = 1) => {
    console.log("Polling voice job", jobId);
    const res = await getJobStatus(jobId, "voice");
    if (res.status === "success") {
      console.log("Voice job complete", res);
      // Update the specific clip's audio_url within the updatedScript
      updateAudioUrlInScript(clipToUpdate, res.audio_url);
    } else if (res.status === "failed") {
      console.error("Voice job failed", res);

      // TODO: handle error
    } else {
      if (attempt >= max_polls) {
        console.error("Voice job polling limit exceeded");
      } else {
        // Exponential backoff with jitter
        setTimeout(
          () => pollVoice(jobId, clipToUpdate, attempt + 1),
          poll_delay_ms * Math.pow(backoff_factor, attempt) +
            Math.random() * 1000
        );
      }
    }
  };

  const updateAudioUrlInScript = (clipToUpdate, newAudioUrl) => {
    setScript((prevScript) => {
      const updatedScriptCopy = { ...prevScript };
      const updatedClipIndex = updatedScriptCopy.clips.findIndex(
        (clip) => clip.audio_url === clipToUpdate.audio_url
      );
      updatedScriptCopy.clips[updatedClipIndex].audio_url = newAudioUrl;
      return updatedScriptCopy;
    });
  };

  const handleConfirm = async () => {
    try {
      const newUuid = await createScratchVideo(script);
      if (newUuid !== null) {
        setShowModal(false);
        console.log("Video regeneration request succeeded.");
        navigate(`/video/${newUuid}`);
      } else {
        console.error("Video regeneration request failed.");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <>
      <Navbar title="Create From Scratch">
        <div>
          <div className="font-semibold mt-2">Create</div>
          <div className="text-sm space-y-2">
            <p>
              Create you video from scratch by first inserting a title and what
              your video is about. Proceed to add some characters and choose
              voices.{" "}
            </p>
            <p>
              After characters are chosen, you can insert clips. Write text for
              your characters to speak, and image prompts to generate images
            </p>
            <p>When you are done customizing your video, click create!</p>
          </div>
          <div className="font-semibold mt-2">Waiting for your video</div>
          <div className="text-sm space-y-2">
            <p>
              While your video is loading, feel free to close this tab and come
              back to it later. Just make sure to copy the URL before you go.
            </p>
            <p>
              You can also browse some videos that others have created, featured
              on our Youtube Channel.
            </p>
          </div>
          <div className="font-semibold mt-2">Watch</div>
          <div className="text-sm space-y-2">
            <p>
              Once your video has loaded, feel free to share this link so others
              can watch, or download your video. To share the video, simply
              click the icon on the top right of the video.
            </p>
          </div>
        </div>
      </Navbar>
      <Header
        title="Create A Video From Scratch"
        subtitle="Turn your weirdest meme idea into reality on your own, powered by AI."
      />
      <div className="flex flex-row sticky top-0 bg-yellow_background-500/[0.85] w-full p-3 z-40">
        <div className="gap-2 mx-auto flex flex-row">
          <Button
            text="Create Video"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </div>
      </div>
      <main>
        <div className="mx-2 md:container">
          <div className="mb-4 space-y-3">
            <div className="mx-auto rounded-md bg-sky-50 border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] w-full max-w-[375px] ">
              <div className="text-xl font-bold mt-4">Plan Your Video</div>
              <div className="max-w-[360px] p-3">
                <div className="flex flex-col mx-1 my-0 items-start w-full">
                  <label className="font-semibold m-1 mt-2">Title</label>
                  <div className="relative inline-block text-left w-11/12">
                    <div className="flex flex-row flex-wrap items-center">
                      <input
                        value={title}
                        onChange={handleTitleChange}
                        className="rounded-md border-2 border-black p-[10px] font-bold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] outline-none transition-all focus:translate-x-[3px] focus:translate-y-[3px] focus:shadow-none"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mx-1 my-0 items-start w-full">
                  <label className="font-semibold m-1 mt-2">
                    Prompt
                    <span className="font-extralight text-sm text-left">
                      {" "}
                      - What will your video be about?
                    </span>
                  </label>
                  <div className="relative inline-block text-left w-11/12">
                    <div className="flex flex-row flex-wrap items-center">
                      <textarea
                        type="text"
                        value={prompt}
                        onChange={handlePromptChange}
                        className="h-[100px] w-full md:min-w-[325px] resize-none rounded-md border-2 border-black p-[10px] shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] outline-none transition-all focus:translate-x-[3px] focus:translate-y-[3px] focus:shadow-none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Characters
              script={script}
              onAcceptVoice={handleReplaceVoice}
              onAddCharacter={handleAddCharacter}
            />
            <div className="flex text-center mt-2 items-center">
              <div className="flex-grow px-3">
                <hr className="border-t border-black" />
              </div>
              <span className="font-bold text-2xl">Clips</span>
              <div className="flex-grow px-3">
                <hr className="border-t border-black" />
              </div>
            </div>
            {script.characters.length > 0 ? (
              <>
                <div className="w-full relative m-auto">
                  <AddClip
                    script={script}
                    onAddClip={handleAddClip}
                    clipIndex={0}
                    creatingFromScratch={true}
                  />
                  {script.clips?.map((clip, index) => (
                    <React.Fragment key={index}>
                      <ClipCard
                        index={index}
                        clip={clip}
                        script={script}
                        onEdit={(editedClip, index) =>
                          handleEditClip(editedClip, index)
                        }
                        onDelete={handleDeleteClip}
                        creatingFromScratch={true}
                      />
                      <AddClip
                        script={script}
                        onAddClip={handleAddClip}
                        clipIndex={index + 1}
                        creatingFromScratch={true}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </>
            ) : (
              <div className="text-center">
                <p>Add characters to your script to begin adding clips.</p>
              </div>
            )}
          </div>
        </div>
      </main>

      <Modal
        active={bulkAudioRegenerationInProgress}
        closeable={false}
        dialogClassName="rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] z-50"
      >
        <div className="text-xl font-bold mt-4">Audio Regenerating</div>
        <div className="relative inline-block p-2 text-left">
          <div className="w-full">
            Regenerating audio for {unfinishedOutdatedAudioUrls.length} more
            clip{unfinishedOutdatedAudioUrls.length > 1 ? "s" : ""} out of{" "}
            {oldAudioUrls.length}. Please wait...
          </div>
          <img
            src={loading_animation}
            alt="Loading animation"
            className="w-[100px] mx-auto mt-1"
          />
        </div>
      </Modal>

      <Modal
        active={showModal}
        setActive={() => setShowModal(false)}
        dialogClassName="rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
      >
        <div className="text-xl font-bold mt-4">You Sure About That?</div>
        <div className="p-4">
          Are you sure you're done editing this video and are ready to create
          it? This will end the creation process and generate your video.
        </div>
        <div className="w-100 flex flex-row justify-between gap-2 p-2">
          <Button
            text="Cancel"
            onClick={() => setShowModal(false)}
            color={`bg-stone-300`}
          />
          <Button text="Confirm" onClick={handleConfirm} />
        </div>
      </Modal>
    </>
  );
}
